@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --color-primary: #FF4136;
  --color-secondary: #0074D9;
  --color-accent: #FF851B;
  --color-background: #F8F9FA;
  --color-text: #333333;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

.transition-all {
  transition: all 0.3s ease;
}

.hover-lift {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}